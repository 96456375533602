import React from 'react';
import Layout from '../../../components/shared/Layout';
import SEO from '../../../components/shared/SEO';
import css from './EcommerceImageProcessing.module.scss';
import {useTranslation} from 'react-i18next';
import {graphql, useStaticQuery} from 'gatsby';
import {getImagesFluid, viewports} from '../../../components/componentsUtils';
import useBreakpoint from "../../../components/hooks/useBreakpoint";
import SlideTypeF from "../../../components/shared/ImageSlider/SlideTypeF";
import {getRoutes} from "../../../components/shared/routes";
  

const query = graphql` 
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_UseCases"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;


const WebPortalImageProcessing = () => {
  const { t } = useTranslation();
  const imagesFluid = getImagesFluid(useStaticQuery(query));

  
  const breakpoint = useBreakpoint();

  const getSlide1ImageWrapperStyles = () => {
    switch (breakpoint.viewport) {
      case viewports.tabletViewport:
        return {
          width: 'calc(100% - 10px)',
          maxWidth: '500px',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '0',
          marginBottom: '0'
        };
      default:
        return {
          width: '50%',
        };
    }
  };


  return (
    // <Layout>
      // <SEO  />

      <div className={css.wrapper}>

      <div className={css.WebPortal}>
            <div className={css.slide1}>
               <SlideTypeF
                    // title=""
                    text1={<p>Automatically tag, add and append image<br/> metadata at scale with our customizable <br/>workflows, including <strong>object identification,<br/> removal and augmentation.</strong></p>}
                    // text2={<p>Aged photos gaining incredible look.</p>}
                    fullImage={'use_cases_2.jpg'}
                    fullImageAlt="Automatically tag, add and append image metadata at scale with our customizable workflows"
                    phoneImage1={'use_cases_2.jpg'}
                    phoneImage1Alt="Automatically tag, add and append image metadata at scale with our customizable workflowsn"
                    // phoneImage2={'e-commerce.png'}
                    // phoneImage2Alt="Predefined workflows to handle image alterations at volume"
                    imagesFluid={imagesFluid}
                    imagePosition={'right'}
                    fullImageWrapperStyles={getSlide1ImageWrapperStyles()}
                />
            </div>
          
        </div>
        </div>
        
    // </Layout>
  );
};

export default WebPortalImageProcessing;
