import React, {useState, useRef, useEffect} from "react";
import css from './coveredVideo3.module.scss';
import {renderImageByFileName, viewports} from "../../componentsUtils";
import Video from "../Video/Video";
import useBreakpoint from "../../hooks/useBreakpoint";
import BackgroundImg from "../../../images/home/green_background.svg";


const CoveredVideo = props => {
  const {
      videoSrcURL,
      videoWidth = '50%',
      placeholderImage,
      placeholderImageAlt = '',
      imagesFluid = [],
      icon,
      title,
      text,
      buttons = null,
      imagePosition = 'left',
      background = true,
      backgroundRotation = '0deg'
  } = props;

  const mountedRef = useRef(true);

  useEffect(() => {
      return () => {
          mountedRef.current = false;
      }
  }, []);

  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const videoHiddenClass = showPlaceholder ? css.hidden : '';

  const imagePositionClass = imagePosition === 'right' ? css.right : css.left;

  const breakpoint = useBreakpoint();

  const videoMargin = (breakpoint.viewport === viewports.desktopViewport) ? '35px' : 'auto';
  const videoWidthCalculated = (breakpoint.viewport === viewports.phoneViewport) ? '90%' : videoWidth;
  const textWidth = (breakpoint.viewport === viewports.desktopViewport) ? `calc(100% - ${videoWidthCalculated} - 2 * ${videoMargin})` : videoWidthCalculated;

  return (
    <div className={[css.wrapper, imagePositionClass].join(' ')}>
      <div className={css.videoContent} style={{ width: videoWidthCalculated, margin: `0 ${videoMargin}` }}>
          { background &&
          <img
              className={css.background}
              src={BackgroundImg}
              alt={""}
              style={{ transform: `rotateZ(${backgroundRotation})`}}
          />
          }
          { showPlaceholder && placeholderImage &&
              <div className={css.placeholderImageWrapper}>
                  {renderImageByFileName(imagesFluid, placeholderImage, placeholderImageAlt)}
              </div>
          }
          <div className={[css.videoWrapper, videoHiddenClass].join(' ')}>
              <Video
                  videoSrcURL={videoSrcURL}
                  onLoad={() => {
                      if (mountedRef.current) {
                          setShowPlaceholder(false)
                      }
                  }}
              />
          </div>
      </div>
      <div className={css.textContent} style={{ width: textWidth }}>
          {(icon || title) &&
          <div className={css.header}>
              {icon &&
              <div className={css.icon}>
                  <img src={icon} alt=""/>
              </div>
              }
              {title &&
              <div className={css.title}>
                  {title}
              </div>
              }
          </div>
          }
          {text &&
          <div className={css.text}>
              {text}
          </div>
          }
          <div className={css.buttonsWrapper}>
              {buttons}
          </div>
      </div>
    </div>
  )
};

export default CoveredVideo;