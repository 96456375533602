import React from "react";
import css from './slideTypeF.module.scss';
import {renderImageByFileName, renderFixedHeightImageByFileName, viewports} from "../../componentsUtils";
import useBreakpoint from "../../hooks/useBreakpoint";
import InspirationsSlideTitle from "./InspirationsSlideTitle";

const SlideTypeF = props => {
  const phoneImageHeight = 312;
  const {
      title,
      text1,
      text2,
      text3,
      fullImage,
      fullImageAlt,
      fullImageWrapperStyles = {},
      phoneImage1,
      phoneImage1Alt,
      phoneImage1Height = phoneImageHeight,
      phoneImage2,
      phoneImage2Alt,
      phoneImage2Height = phoneImageHeight,
      phoneImage3,
      phoneImage3Alt,
      phoneImage3Height = phoneImageHeight,
      imagesFluid = [],
      imagePosition = 'left'
  } = props;

  const breakpoint = useBreakpoint();
  
  const getImageWrapperDefaultStyles = () => {
    switch (breakpoint.viewport) {
      case viewports.phoneViewport:
        return {
          width: 'calc(100% - 10px)',
          marginLeft: '0px'
        };
      case viewports.tabletViewport:
        return {
          width: 'calc(100% - 10px)',
          maxWidth: '680px',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '0',
          marginBottom: '0'
        };
      default:
        return {
          width: '680px'
        };
    }
  };

  const imageWrapperStylesMerged = {
      ...getImageWrapperDefaultStyles(),
      ...fullImageWrapperStyles
  };

  const imagePositionClass = imagePosition === 'right' ? css.right : css.left;

  if (breakpoint.viewport === viewports.phoneViewport) {
      return (
          <div className={[css.phoneWrapper, imagePositionClass].join(' ')}>
              <div className={css.phoneTitle}><InspirationsSlideTitle title={title} /></div>
              <div className={css.phoneImagesWrapper}>
                  { phoneImage1 && <div className={css.phoneImageWrapper}>{renderFixedHeightImageByFileName(imagesFluid, phoneImage1, phoneImage1Alt, phoneImage1Height)}</div> }
                  { text1 && <div className={css.phoneTextWrapper}><div>{text1}</div></div>}
                  { phoneImage2 && <div className={css.phoneImageWrapper}>{renderFixedHeightImageByFileName(imagesFluid, phoneImage2, phoneImage2Alt, phoneImage2Height)}</div> }
                  { text2 && <div className={css.phoneTextWrapper}><div>{text2}</div></div>}
                  { phoneImage3 && <div className={css.phoneImageWrapper}>{renderFixedHeightImageByFileName(imagesFluid, phoneImage3, phoneImage3Alt, phoneImage3Height)}</div> }
                  { text3 && <div className={css.phoneTextWrapper}><div>{text3}</div></div>}
              </div>
          </div>
      );
  }

  return (
    <div className={css.wrapper}>
      <div className={[css.content, imagePositionClass].join(' ')}>
          <div className={css.imageWrapper} style={imageWrapperStylesMerged}>
              {renderImageByFileName(imagesFluid, fullImage, fullImageAlt)}
          </div>
          <div className={css.textWrapper}>
              <div className={css.title}>
                <InspirationsSlideTitle title={title} />
              </div>
              <div className={css.text}>
                  {text1}
                  {text2}
                  {text3}
              </div>
          </div>
      </div>
    </div>
  )
};

export default SlideTypeF;