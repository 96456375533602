import React from 'react';
import {getRoutes} from '../components/shared/routes';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import css from './use_cases.module.scss';
import Footer from '../components/shared/Footer/Footer';
import CoveredVideo3 from "../components/shared/CoveredVideo/CoveredVideo3";
import RestorationMovie from "../movies/UsesCases/Restoration.mp4";
import WebPortalImage from "../movies/UsesCases/WebPublishing.mp4";
import EcommerceImage from "../movies/UsesCases/OnlineSales.mp4";
import InspirationsSlideTitle from "../components/shared/ImageSlider/InspirationsSlideTitle";

import EcommerceImageProcessing from "../components/pages/UseCasesPage/EcommerceImageProcessing";
import WebPortalImageProcessing from "../components/pages/UseCasesPage/WebPortalImageProcessing";
import OldPhoto from "../images/UseCases/use_cases_3.jpg";
import OldPhoto2 from "../images/UseCases/use_cases_4.jpg";



const UseCasesPage = () => {
  
  const pageTitle = `${getRoutes().UseCases.pageTitle}`;

  const title = (title) =>{
    return(
    <InspirationsSlideTitle title={title} />
    );
  };

  return (
    <Layout>
      <SEO title={pageTitle} description={'Use Cases'}/>

      <div className={css.wrapper}>

        <div className={css.EcommerceImage} >
            <CoveredVideo3
            videoSrcURL={EcommerceImage}
            imagePosition={'right'}
            // title={"Ecommerce Image Processing"}
            text={<>{title(<>Ecommerce Image Enhancement</>)}<p>Increase sales and product conversions <br/>with higher quality and sharper images.<br/><br/> Try our automated workflows for full<br/> product image enhancement.</p></>}
            />
             <EcommerceImageProcessing />
        </div>
        
        
        <div className={css.WebPortalImage} >
          <CoveredVideo3 
            videoSrcURL={WebPortalImage}
            // title="Web Portal Image Processing"
            text={<>{title(<>Web Portal Image Processing</>)}<p>Use our predefined workflows to handle <br/>high volume image processing that can <br/>include image enhancement, face <br/>identification, headshot cropping, <br/>dedicated to <strong>news, posts, events, dating.</strong></p></>}
          />
          <WebPortalImageProcessing />
        </div>

        <div className={css.RestorationMovie} >
          <CoveredVideo3
            videoSrcURL={RestorationMovie}
            // title="Old Photo Restoration &amp; Processing"
            text={<>{title(<>Old Photo Restoration &amp; Processing</>)}<p>Restore old photos individually or at scale <br/>with our pre-defined image restoration <br/>workflows.</p></>}
            imagePosition={'right'}
          />
          
          <div className={css.contentWrapper}>
            <div className={css.imageWrapper}>
             
            </div>
          </div>
        </div>

        <div className={css.Restoration}>
          <div className={css.Normalwrapper}>
            <div className={css.text}>
            Restore and enhance old photos and store <br/>them forever in a digital format.
            </div>
            <div className={css.imageWrapper}>
            <img src={OldPhoto} alt={""} />
          </div>
            <div className={css.imageWrapper2}>
              <img src={OldPhoto2} alt={""} />
            </div>

          </div>

          <div className={css.Phonewrapper}>
          <div className={css.imageWrapper}>
            <div className={css.imageWrapper2}>
              <img src={OldPhoto2} alt={""} />
            </div>
            <div className={css.text}>
              Restore and enhance old photos and store <br/>them forever in a digital format.
            </div>
            <div className={css.imageWrapper}>
              <img src={OldPhoto} alt={""} />
            </div>


          </div>
          </div>
          
          
        </div>
          <Footer/>
       

        </div>
    </Layout>
  );
};

export default UseCasesPage;

