import React from "react";
import BoxHeader from "../BoxHeader/BoxHeader";

const InspirationsSlideTitle = props => {
  const {
      title,
  } = props;

  if (!title) {
      return null;
  }

  return (
      <BoxHeader style={{ fontSize: '1.45rem', lineHeight: '2.05rem', fontWeight: 700, color: '#373A3C', marginBottom: '30px' }} lineColor={'green'} contentAligned={false}>{title}</BoxHeader>
  )
};

export default InspirationsSlideTitle;