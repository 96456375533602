
import React from 'react';
import Layout from '../../../components/shared/Layout';
import SEO from '../../../components/shared/SEO';
import css from './EcommerceImageProcessing.module.scss';
import {useTranslation} from 'react-i18next';
// import Footer from "../../../components/shared/Footer/Footer";
import {graphql, useStaticQuery} from 'gatsby';
import {getImagesFluid, viewports} from '../../../components/componentsUtils';
import useBreakpoint from "../../../components/hooks/useBreakpoint";
// import CoveredVideo from "../../components/shared/CoveredVideo/CoveredVideo";
import SlideTypeF from "../../../components/shared/ImageSlider/SlideTypeF";
// import Movie from "../../movies/Inspirations/AgedPhoto/inspirations-photos-restoration.mp4";
// import RestorationIconBlack from "../../images/icons/restoration_b.svg";
// import GoToButton from "../../components/shared/GoToButton/GoToButton";
// import {getRoutes} from "../../../components/shared/routes";
  

const query = graphql` 
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_UseCases"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;


const EcommerceImageProcessing = () => {
  const { t } = useTranslation();
  const imagesFluid = getImagesFluid(useStaticQuery(query));

  
  const breakpoint = useBreakpoint();

  const getSlide1ImageWrapperStyles = () => {
    switch (breakpoint.viewport) {
      case viewports.tabletViewport:
        return {
          width: 'calc(100% - 10px)',
          maxWidth: '500px',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '0',
          marginBottom: '0'
        };
      default:
        return {
          width: '50%',
        };
    }
  };

  return (
    <Layout>
      <SEO  />

      <div className={css.wrapper}>

      <div className={css.Ecommerce}>
            <div className={css.slide1}>
               <SlideTypeF
                    // title=""
                    text1={<p>Try our predefined automated workflows <br/>for specific image types. </p>}
                    text2={<p>Our predefined workflows can enhance <br/>and sharpen many image types, including <br/><strong>vehicle images, eCommerce product <br/>images, real-estate images and more.</strong></p>}
                    fullImage={'use_cases_1.jpg'}
                    fullImageAlt="Try our predefined automated workflows for specific image types."
                    phoneImage1={'use_cases_1.jpg'}
                    phoneImage1Alt="Try our predefined automated workflowsfor specific image types."
                    // phoneImage2={'e-commerce.png'}
                    // phoneImage2Alt="Predefined workflows to handle image alterations at volume"
                    imagesFluid={imagesFluid}
                    imagePosition={'left'}
                    fullImageWrapperStyles={getSlide1ImageWrapperStyles()}
                />
            </div>
          
        </div>
        </div>
    
     
    </Layout>
  );
};

export default EcommerceImageProcessing;
